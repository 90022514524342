import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import CMSView from '../components/View';
import requireUser, { UserProps } from '../utils/requireUser';

const PageHome: FC<PageProps & UserProps> = ({ user }) => (
  <Layout showConsole showNavigation={false}>
    <CMSView>
      <Header
        description="Je te souhaite une bonne journée."
        title={`Bonjour ${user.firstName},`}
      />
    </CMSView>
  </Layout>
);

export default requireUser(PageHome);
